// eslint-disable-next-line no-redeclare,no-unused-vars
function buildView(param) {
  var object = param.object;
  var objectSub = param.objectSub;
  var ident = [object, objectSub, param.id].filter(Boolean).join('_');

  // prepare
  dashboard.hide();
  if (views.thinView)
    views.thinView.close({
      noViewsHandle: true,
      noOverviewHandle: true,
      force: true,
    });
  if (views.detail && !param.keepDetail && !param.thinView)
    views.detail.close({
      noViewsHandle: true,
      noOverviewHandle: true,
      force: true,
    });
  if (views.overview && !param.keepOverview) {
    if (param.overview && views.overview.ident == ident) {
      views.overview.show(true);
      return false;
    } else if (param.overview) views.overview.close({noViewsHandle: true});
    else if (param.detail && views.overview.ident == param.objIdent)
      views.overview.hide(true);
    else if (param.detail && !param.thinView)
      views.overview.close({noViewsHandle: true});
  }

  // build view
  var view = $('<div class="view" ident="' + ident + '" tabindex="0"></div>');
  if (param.overview) view.prependTo(views.row);
  else view.appendTo(views.row);
  var contRow = $('<div class="row"></div>').appendTo(view);
  view.left = $('<div class="col-sm-3 col-xl-2 view-left"></div>').appendTo(
    contRow
  );
  view.main = $(
    '<div class="col-sm-6 col-xl-8 view-main flex-sm-grow-1"></div>'
  ).appendTo(contRow);
  view.right = $(
    '<div class="col-sm-3 col-xl-2 view-right" data-mdb-perfect-scrollbar="true"></div>'
  ).appendTo(contRow);
  if (param.overview) view.overview = true;
  else if (param.detail) view.detail = true;

  if (param.bodyCard) {
    view.left.addClass('d-none');
    // view.right.addClass('d-none');
    var contCard = $('<div class="card"></div>').appendTo(view.main);
    view.body = $('<div class="card-body"></div>').appendTo(contCard);
    if (param.scrollable) view.body.addClass('scrollable');
  }

  // header
  view.header = $('<div class="view-header d-flex"></div>').prependTo(
    view.main
  );
  var contTitle = $(
    '<div class="view-title d-flex align-items-center me-3"></div>'
  ).appendTo(view.header);
  var contTitle2 = $('<h2 class="h4 mb-0"></h2>').appendTo(contTitle);
  addTitle(view, contTitle2);
  view.setTitle(param.title, getParamSettings(param, 'icon'));
  view.buttonsLeft = $(
    '<div class="action-buttons action-buttons-left me-auto"></div>'
  ).appendTo(view.header);

  // view params
  param.view = view;
  view.ident = ident;
  view.type = param.type;
  view.object = object;
  view.objectSub = objectSub;
  view.modal = false;
  if (param.detail && !param.thinView) views.detail = view;
  else if (param.detail && param.thinView) views.thinView = view;
  else if (param.overview) {
    view.addClass('view-overview');
    views.overview = view;
  }
  viewStack[ident] = view;

  // buttons
  view.buttonsRight = $(
    '<div class="action-buttons action-buttons-right"></div>'
  ).appendTo(view.header);
  setTimeout(function () {
    if (param.object && !param.noUpdate && mobile != 'smartphone')
      $(
        '<button class="btn btn-light btn-default"><i class="icon fa-regular fa-sync fa-fw"></i></button>'
      )
        .appendTo(view.buttonsRight)
        .on(click, function () {
          if (view.id == 'new') return;
          if (tableCache[view.ident]) delete tableCache[view.ident];
          view.update({update: true});
        })
        .addTooltip('Ansicht aktualisieren');
    $(
      '<button class="btn btn-light btn-default"><i class="icon fa-regular fa-times fa-fw"></i></button>'
    )
      .appendTo(view.buttonsRight)
      .on(click, function () {
        if (!view.detail && view.overview) views.overview.hide();
        else view.close();
      })
      .addTooltip('Schließen [Esc]');
  }, 500);

  // $('<button class="btn btn-light"><i class="icon fa-regular fa-square fa-fw"></i></button>')
  // 	.appendTo(view.buttonsRight)
  // 	.on(click, function () {
  // 		view.setWidth(12);
  // 	})
  // 	.addTooltip('Maximieren');

  //$('<button class="btn btn-light"><i class="icon fa-regular fa-tv fa-fw"></i></button>').appendTo(view.buttonsRight).on(click,function(){ dialogPresentation(view); }).addTooltip('Präsentation');
  // var posBtn = $('<button class="btn btn-light pos multi"></button>').appendTo(view.buttonsRight);
  // $('<span class="attachLeft"><i class="icon fa-regular fa-hand-point-left fa-fw"></i></span>')
  // 	.appendTo(posBtn)
  // 	.on(click, function () {
  // 		var pm = {width: dim.width, position: dim.left, positionStr: 'left'};
  // 		if (dim.quarter && view.position == 'left') pm.width = dim.quarter;
  // 		view.pos(pm);
  // 	})
  // 	.addTooltip('Links anheften');
  // $('<span class="attachRight"><i class="icon fa-regular fa-hand-point-right fa-fw"></i></span>')
  // 	.appendTo(posBtn)
  // 	.on(click, function () {
  // 		var pm = {width: dim.width, position: dim.right, positionStr: 'right'};
  // 		if (dim.quarter && view.position == 'right') pm.width = dim.quarter;
  // 		view.pos(pm);
  // 	})
  // 	.addTooltip('Rechts anheften');
  // var pinBtn = $('<button class="btn btn-light pinDialog"><i class="icon fa-regular fa-thumbtack fa-fw"></i></button>')
  // 	.appendTo(view.buttonsRight)
  // 	.on(click, function () {
  // 		if (view.pinned) delete view.pinned;
  // 		else view.pinned = true;
  // 		// view.removeClass('ui-front').css({zIndex:1000});
  // 		$(this).toggleClass('active');
  // 	})
  // 	.addTooltip('Dialog anpinnen');
  //	setTimeout(function(){ view.buttonsWidth = titlebar.find('.buttons1').width()+buttons.width()+titlebar.find('.ui-dialog-title').width(); view.resize(); },100);

  view.setWidth = function (size) {
    $.each(view.attr('class').split(' '), function (i, className) {
      if (str_starts_with(className, 'col-sm')) view.removeClass(className);
    });
    view.addClass('col-sm-' + size);

    var width = view.width();
    if (size < 9 || width < 1000) view.addClass('view-small');
    else view.removeClass('view-small');

    if (view.setContentWidth) view.setContentWidth(width);
  };
  view.setHeight = function () {
    var height = views.height() - 50;
    view.find('.view-header,nav').each(function () {
      height -= $(this).outerHeight();
    });
    view.body.siblings(':visible:not(.view-header)').each(function () {
      height -= $(this).outerHeight();
    });

    var viewportHeight = $(window).height();

    // only on bigger screensizes
    if (viewportHeight >= 700) {
      view.body.css('max-height', height);
    }

    if (view.setContentHeight) view.setContentHeight(height);
  };
  view.update = function (param2) {
    updateView(param, param2);
  };
  view.show = function (noHistory) {
    dashboard.hide();
    view.removeClass('d-none');
    handleViews();
    if (!noHistory) {
      addToHistory(param);
      view[0].focus();
      views[0].scrollTop = 0; // scrool bug on view focus
    }
  };
  view.hide = function (noViewsHandle) {
    view.addClass('d-none');
    if (!noViewsHandle) handleViews();
  };
  view.close = function (param2 = {}) {
    if (view.beforeClose) {
      var result = view.beforeClose();
      if (result === false && !param2.force) return;
    }

    view.remove();
    delete viewStack[ident];

    if (view.overview) {
      delete views.overview;
      if (views.detail)
        views.detail.close({noViewsHandle: true, noOverviewHandle: true});
    } else if (view.detail && view.hasClass('view-small') && views.thinView)
      delete views.thinView;
    else if (view.detail) {
      delete views.detail;
      if (view.openOverview && !param.noOverview && !param2.noOverviewHandle)
        view.openOverview();
      // if (views.overview && views.overview.is(':visible')) view.openOverview();
    }

    // callLastHistory();
    if (!param2.noViewsHandle) handleViews();

    if (view.afterClose) view.afterClose();
  };

  // events
  if (param.afterOpen) {
    param.afterOpen();
    view.afterOpen = param.afterOpen;
  }
  if (param.beforeClose) {
    view.beforeClose = param.beforeClose;
    delete param.beforeClose;
  }
  if (param.afterClose) {
    view.afterClose = param.afterClose;
    delete param.afterClose;
  }

  view.show();
  return view;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function handleViews() {
  var visible = views.row.children('.view:not(.d-none)');
  var amount = visible.length;
  var sizes = [];

  if (!amount) dashboard.show();
  else if (amount == 1)
    views.addClass('view-single').removeClass('view-duo view-trio');
  else if (amount == 2)
    views.addClass('view-duo').removeClass('view-single view-trio');
  else if (amount == 3)
    views.addClass('view-trio').removeClass('view-single view-duo');

  visible.each(function (i) {
    var view = viewStack[$(this).attr('ident')];
    if (!view) return;

    var size = 0;
    if (amount == 1) size = 12;
    else if (amount == 2) {
      if (view.overview) size = 3;
      else if (view.detail && i == 0) size = 6;
      else if (view.detail && i == 1 && sizes[0] == 3) size = 9;
      else if (view.detail && i == 1 && sizes[0] == 6) size = 6;
    } else if (amount == 3 && view.overview) {
      if (view.overview) size = 3;
      else if (view.detail && i == 1) size = 6;
      else if (view.detail && i == 2) size = 3;
    } else if (amount == 3) size = 4;

    view.setWidth(size);
    sizes.push(size);
  });
}
